import React from "react"
import {useStaticQuery,graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import people from "../../icons/pages/about-people.svg"
import NavAbout from "../../components/nav/about"
import {LinkedinIcon} from "react-share"

const About = () =>{
    const data = useStaticQuery(graphql`
        query{
            author1: file(relativePath: {eq: "images/authors/author1-s.png"}) {
                childImageSharp { 
                    fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
            author2: file(relativePath: {eq: "images/authors/author2-s.png"}) {
                childImageSharp { 
                    fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
            author3: file(relativePath: {eq: "images/authors/author3-s.png"}) {
                childImageSharp { 
                    fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const author1 = data.author1.childImageSharp
    const author2 = data.author2.childImageSharp
    const author3 = data.author3.childImageSharp

    return(
        <Layout title="最专业【VPN翻墙、科学上网】评测 - 关于我们｜翻墙101">
            <NavAbout />
            <div id="us" className="about">
                <div className="title" >
                    <div className="container">
                        <h1>关于我们</h1>
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <p>由于墙的存在，打消了很多海外华人和“老外”对于前往中国的打算。我们希望自己能够做些什么，改变这种现状。而在互联网领域中，充斥着各种各样的欺骗与假象，我们同样希望我们的网站能够带来一些少有的真诚。</p>
                                <p>翻墙101就是我们这种愿望的化身。</p>
                                <p>我们力图通过最专业科学的评测，来给我们的用户带来最具价值，最有效的VPN推荐。我们通过公正的评比和测试，来为您提供最方便，最具科学性的VPN比较。</p>
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={people} alt="团队" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content container">
                    <div className="row author" id="author1">
                        <div className="col-4 author-img">
                            <Img fluid={author1.fluid} />
                        </div>
                        <div className="col-8">
                            <h3>Mark Zhang</h3>
                            <p><a href="https://www.linkedin.com/in/mark-zhang-54a1201b6/"><LinkedinIcon size={32} round={true} /></a></p>
                            <p>Mark 是wall101.com (翻墙101) 的技术负责人，而他对于数据和科技的理解在我们的评测中起了很大的作用。</p>
                            <p>他提出了我们采用至今的评测体系，通过6大维度来评价翻墙VPN。他为整个网站提供了高效的技术架构并带头撰写了一系列有关安全，个人隐私以及网络技术的研究和指南。</p>
                            <p>在加入wall101之前，Mark曾担任: 
                                <ul>
                                <li>阿里巴巴公司高级技术工程师</li>
                                <li>谷歌 (Palo Alto总部) 安卓开发工程师</li>
                                </ul>
                            </p>
                            
                        </div>
                    </div>
                    <div className="row author" id="author2">
                        <div className="col-4 author-img">
                            <Img fluid={author2.fluid} />
                        </div>
                        <div className="col-8">
                            <h3>Lauren Liu</h3>
                            <br />
                            <p>Lauren 是我们的主要编辑，她帮助修订，润色我们的所有文章。她活泼的风格让这些工科男枯燥的技术报告有了生动形象的色彩。</p>
                            <p>通过在不同国家的生活，不同文化的感受以及不同语言的学习中，她对于让不同背景的用户都能够轻松理解复杂的概念有很深的体会。Lauren还为我们撰写了很多有价值的小白操作手册，让对各种技术术语头晕的朋友们也能够轻松上手。</p>
                            </div>
                    </div>
                    <div className="row author" id="author3">
                        <div className="col-4 author-img">
                            <Img fluid={author3.fluid} />
                        </div>
                        <div className="col-8">
                            <h3>Eric Chou</h3>
                            <br />
                            <p>Eric 作为wall101.com的安全专家，为我们在VPN的安全性，VPN网络连接技术以及翻墙的技术原理方面为我们提供了重要的见解。</p>
                            <p>Eric本科就读于中国著名的清华大学，在一次机缘巧合下，Eric对计算机技术着了迷，从此也走上了CS的道路。Eric 目前在加拿大的多伦多大学从事密码学与分布式系统的博士研究。如何保护用户的隐私，保障每个人的上网安全也是他关注的焦点所在。</p>
                            <p>Eric 深入浅出的为我们撰写了VPN以及翻墙的技术原理和安全报告，Eric对于网络安全的追求与执着也深深地影响着我们团队中的每个人。</p>
                            </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
   
}

export default About