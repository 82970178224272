import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby"
import "../../styles/parts/about.sass"

const NavAbout = ()=>{
    const data = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            footer {
              about{
                title
                ps {
                  p
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const content = data.allDataJson.edges.filter((edge)=> {return edge.node.footer !== null})[0].node.footer
  const navigations = content.about

  return(
    <nav id="nav-about">
      <ol >
        {navigations.ps.map((link)=>(
          <li><Link to={link.url}
            activeClassName="active"
          >{link.p}</Link></li>
        ))}
      </ol>
    </nav>
  )
}

export default NavAbout